import { Brand } from './typings'

const gap: Brand = {
  clientInfo: {
    displayName: 'Gap',
    accountNumberLengths: [16],
    footerDisplay: 'Gap.com',
    footerLink: 'https://www.Gap.com/',
    applicationUrl: '',
    dsecApigeeClientId: {
      development: 'hnUYGr7cyE2B8Fe0Z9GvQsokGAnaHzGz',
      production: 'hpXGyA6x9MvZlDyifibC93JlGy6SQNtF'
    },
    persistApigeeClientId: { development: '', production: '' },
    setpayApigeeClientId: {
      development: '',
      production: ''
    },
    languages: ['en'],
    vanityUrl: 'Gap.com',
    hostname: 'gap.syf.com',
    linkingRedirect: '',
    ivaKey: '',
    hasMss: true,
    hasCpl: false,
    promoUrl: 'https://gap.barclaysus.com/',
    hasCplOldErrorCode: false,
    isMultiAccount: false,
    footerContent: {
      en: 'Your Gap Good Rewards Visa® Credit Card, Gap Good Rewards Credit Card, or Gap Inc. Visa Signature® Card is issued by Synchrony Bank. The Synchrony Bank Privacy Policy governs the use of the Gap Good Rewards Visa® Credit Card, Gap Good Rewards Credit Card, or Gap Inc. Visa Signature® Card. The use of this site is governed by the use of the Synchrony Bank Online Privacy Policy, which is different from the privacy policy of Gap.',
      es: ''
    },
    metaTagCardTitle: {
      en: 'Credit Card',
      es: 'Tarjeta de Crédito'
    },
    metaTagCardDescription: {
      en: `Log into your Gap credit card account online to pay your bills, check your FICO score, sign up for paperless billing, and manage your account preferences`,
      es: `Ingrese a su cuenta de la Tarjeta de Crédito Gap en línea para pagar sus facturas, revisar su puntuación FICO, inscribirse en facturas digitales y manejar su cuenta.`
    },
    phoneNumbers: {
      loan: '',
      plcc: '1-866-450-4467',
      dualcard: '1-866-450-4467',
      fraud: '1-877-350-4039',
      customerService: '1-855-605-4360'
    },
    iOSAppId: '',
    androidApp: {
      id: '',
      author: '',
      title: '',
      image: ''
    },
    crossBorder: false,
    cplHeroBannerDisplayName: '',
    termsOfService: {
      en: '/essimages/cs/groups/ess_webasset/dualcard/@gap/en/html/ess014522.html',
      es: ''
    },
    onlinePrivacy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@gap/en/html/ess013967.html',
      es: ''
    },
    privacyPolicy: {
      en: '/essimages/cs/groups/ess_webasset/generic/@gap/en/html/ess014228.html',
      es: ''
    },
    websiteUsage: {
      en: '/essimages/cs/groups/ess_webasset/generic/@gap/en/html/ess014231.html',
      es: ''
    },
    voice: {
      cardName: '',
      google: {
        termsAndConditionsLink: ''
      },
      alexa: {
        termsAndConditionsLink: ''
      }
    }
  },
  palette: {
    field: {
      icon: '#002554',
      focus: '#34657F',
      label: ''
    },
    progressStepBar: {
      fill: '#070E43',
      label: '#070E43'
    },
    progressBar: {
      complete: '',
      error: '#c63527',
      inProgress: '#0072ad',
      background: '#e9eaeb'
    },
    button: {
      primary: {
        text: '#FFFFFF',
        hover: '#1c235c'
      },
      secondary: {
        hover: '#cfd1e5'
      },
      background: '#002554'
    },
    checkbox: {
      background: '#070e43',
      font: '#FFFFFF'
    },
    switch: { background: '' },
    heroBackground: '',
    bodyBackground: '#FFFFFF',
    iconBackground: '#070E43',
    headerBackground: '#FFFFF',
    modalBackground: '#FFFFFF',
    formBackground: '',
    headerDivider: '#00A9EB',
    footerDivider: '#FFFFFF',
    contrastingText: '#FFFFFF',
    bodyText: '#333333',
    languageSelector: '#070e43',
    focus: '',
    link: '#002554',
    footer: {
      background: '#F2f2f2',
      text: '#333333',
      link: '#333333'
    }
  },
  images: {
    logo: {
      en: '/assets/gap/logo.jpg',
      es: '/assets/gap/logo.jpg'
    },
    hero: '',
    heroBgDesktop: '',
    heroBgMobile: '',
    mainPromo: {
      en: '/assets/gap/promo.png',
      es: '/assets/gap/promo.png'
    },
    voiceCplLogo: ''
  }
}

export default gap
